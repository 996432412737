// Below Function Executes On Form Submit
function validateForm()
{
	var error = '';

	if(error.length == 0 && document.fregister.salutation.value == "" )
	error = "Please provide your title";

	if(error.length == 0 && document.fregister.firstname.value == "" )
	error = "Please provide your first name";

	if(error.length == 0 && document.fregister.lastname.value == "" )
	error = "Please provide your last name";

	if(error.length == 0 &&  document.fregister.mobileno.value == "" )
	error = "Please provide your contact number" ;
	
	if(error.length == 0 &&  document.fregister.email.value == "" )
	error = "Please provide your email";

	if(error.length == 0 &&  document.fregister.address_1.value == "" )
	error = "Please provide your address";
	
	if(error.length == 0 && $('input[name="interested_development"]:checked').length == 0)
	error = "Please choose interested property";
	
	if(error.length == 0 && $('input[name="how_you_know"]:checked').length == 0)
	error = "Please choose how did you know about us";
	
	if(error.length == 0)
	{

		var dataString = 'ffs=' + $('[name=ffs]').val();
		dataString = dataString + '&salutation='+$('[name=salutation]').val(); 
		dataString = dataString + '&firstname='+$('[name=firstname]').val(); 
		dataString = dataString + '&lastname='+$('[name=lastname]').val(); 
		dataString = dataString + '&mobileno='+$('[name=mobileno]').val(); 
		dataString = dataString + '&email='+$('[name=email]').val();
		dataString = dataString + '&address_1='+$('[name=address_1]').val();
		dataString = dataString + '&address_2='+$('[name=address_2]').val();
		
		var interested_development = '';
		$('[name=interested_development]:checked').each(function () {
			if(interested_development.length == 0)
			interested_development += this.value;
			else
			interested_development += ','+this.value;
		});
		dataString = dataString + '&interested_development='+interested_development;

		var how_you_know = '';
		$('[name=how_you_know]:checked').each(function () {
			if(how_you_know.length == 0)
			how_you_know += this.value;
			else
			how_you_know += ','+this.value;
		});
		dataString = dataString + '&how_you_know='+how_you_know;
		
		//ajax process
		$.ajax({
			url: '/',//url: $(this).attr("href"), // References "/say/hello?format=json"
			type: 'post',
			contentType:"application/x-www-form-urlencoded",
			data: dataString,
			dataType: "json",
			cache:false,
			timeout : 10000,
		
			success: function(ajaxresponse) {
				//alert(JSON.stringify(ajaxresponse));
				if(ajaxresponse.error.length == 0)
				{
					$('#registerok').modal('show');
			
					$("[name=salutation]").val('');
					$("[name=firstname]").val('');
					$("[name=lastname]").val('');
					$("[name=mobileno]").val('');
					$("[name=email]").val('');
					$("[name=address_1]").val('');
					$("[name=address_2]").val('');
					$("[name=interested_development]").prop('checked', false);
					$("[name=how_you_know]").prop('checked', false);
					$("[name=pdpa]").prop('checked', false);
					$("[name=agree]").prop('checked', false);
				}
				else
				alert(ajaxresponse.error);
			}
			,
			error:function (xhr, ajaxOptions, thrownError){
			  alert(xhr.status);
			  alert('Sending message failed.');
			}    				 
		});

	}
	else
	{
		alert(error);
		error = '';
	}
	
	resetCaptcha();
	return(false);
}


$(document).ready(function(){
	var flag = false;
	$('input[name="interested_development"]:checked').each(function(){
		$('input[name="interested_development"]').removeAttr('required');
	});
	$('input[name="interested_development"]').click(function() {
		flag = false;
		$('input[name="interested_development"]:checked').each(function(){
			flag = true;
		});
		
		if(flag)
		$('input[name="interested_development"]').removeAttr('required');
		else
		$('input[name="interested_development"]').attr('required', 'required');
	});

	flag = false;
	$('input[name="how_you_know"]:checked').each(function(){
		$('input[name="how_you_know"]').removeAttr('required');
	});
	$('input[name="how_you_know"]').click(function() {
		flag = false;
		$('input[name="how_you_know"]:checked').each(function(){
			flag = true;
		});
		
		if(flag)
		$('input[name="how_you_know"]').removeAttr('required');
		else
		$('input[name="how_you_know"]').attr('required', 'required');
	});
});

function resetCaptcha()
{
	$m('.QapTcha').html('');
	$m(document).ready(function(){
	$m('.QapTcha').QapTcha({
		  autoSubmit : true,
		  autoRevert : true,
		  disabledSubmit : false,
		  PHPfile : '/Qaptcha.jquery.php',
		  txtLock : '',
		  txtUnlock : ''
		});
	});
}

function validate(textbox)
{
	resetCaptcha();
	
	if($(textbox).attr("name") == 'salutation')
	{
		if (textbox.value == '') {
			textbox.setCustomValidity('Please select your title.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}
	
	if($(textbox).attr("name") == 'firstname')
	{
		if (textbox.value == '') {
			textbox.setCustomValidity('Please provide your given name.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}
	
	if($(textbox).attr("name") == 'lastname')
	{
		if (textbox.value == '') {
			textbox.setCustomValidity('Please provide your surname.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}

	if($(textbox).attr("name") == 'mobileno')
	{
		var regex_mobile = /^[0-9\-\+]{5,20}$/;

		if (textbox.value == '') {
			textbox.setCustomValidity('Please provide your contact number.');
		}
		else if(textbox.value != '' && regex_mobile.test(textbox.value)==false){
			textbox.setCustomValidity('Please provide a valid contact number.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}
	
	if($(textbox).attr("name") == 'email')
	{
		var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		
		if (textbox.value == '') {
			textbox.setCustomValidity('Please provide your email.');
		}
		else if(textbox.validity.typeMismatch){
			textbox.setCustomValidity('Please provide a valid email.');
		}
		else if(textbox.value != '' && emailReg.test(textbox.value)==false){
			textbox.setCustomValidity('Please provide a valid email.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}

	if($(textbox).attr("name") == 'address_1')
	{
		if (textbox.value == '') {
			textbox.setCustomValidity('Please provide your address.');
		}
		else {
			textbox.setCustomValidity('');
		}
	}
	
	if($(textbox).attr("name") == 'interested_development')
	{
		if (!textbox.checked && textbox.required) {
			textbox.setCustomValidity("Please select one from 'I'M INTERESTED IN*'.");
		}
		else {
			textbox.setCustomValidity('');
		}
	}
	
	if($(textbox).attr("name") == 'how_you_know')
	{
		if (!textbox.checked && textbox.required) {
			textbox.setCustomValidity("Please select one from 'HOW DID YOU KNOW ABOUT MILLERZ SQUARE*'.");
		}
		else {
			textbox.setCustomValidity('');
		}
	}
}

