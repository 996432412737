$(document).ready(function() {
    //focuspoint
	$('.focuspoint').focusPoint();

	//Init show on document ready
	TweenMax.to($(".coffee-cup-logo"), 1, {autoAlpha: 1});

	//Animate homepage box
	function animBox(){
		tl = new TimelineMax({ ease: Expo.easeOut});
		tl.fromTo($(".title-mask"), 1, {width: "0"},{width: "100%"}, "1")
			.fromTo($(".decor-line"), 0.6, {width: "0"},{width: "60px"}, "-=0.5")
			.fromTo($(".section-home .btn-primary"), 0.8, {y: 10, autoAlpha: 0},{y: 0, autoAlpha: 1}, "-=0.3");
		modalInit();
	}

	// Open modal
	function modalInit(){
		$("#modal-events").modal("show");
	}

	//Mobile detect
	var isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function() {
			return navigator.userAgent.match(/iPhone|iPod/i);
		},
		iPad: function() {
			return navigator.userAgent.match(/iPad/i);
		},
		Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
		any: function() {
			return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS()  || isMobile.iPad() ||  isMobile.Opera() || isMobile.Windows());
		}
	};


	// Percentage Increment Animation
	var width = 100, // width of a progress bar in percentage
		perfData = window.performance.timing, // The PerformanceTiming interface
		EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart), // Calculated Estimated Time of Page Load which returns negative value.
		time = parseInt((EstimatedTime/1000)%60)*100; //Converting EstimatedTime from miliseconds to seconds.

	var PercentageID = $("#precent"),
			start = 0,
			end = 100,
			duration = time;
			animateValue(PercentageID, start, end, duration);

	function animateValue(id, start, end, duration) {
		var range = end - start,
		current = start,
		increment = end > start? 1 : -1,
		stepTime = Math.abs(Math.floor(duration / range)),
		obj = $(id);

		var timer = setInterval(function() {
			current += increment;
			$(obj).text(current + "%");
		//obj.innerHTML = current;
			if (current == end) {
				clearInterval(timer);
			}
		}, stepTime);
	}

	// Coffee Loadbar Animation
	$(".coffee").animate({
		height: width + "%"
	}, time, "linear", function() {
		$( "body" ).addClass("loaded");
		animBox();
		console.log("complete");
	});


	//Snap Svg Logo
	var s = Snap(".svg-container"),
		getS = $(".svg-container").data("src");
		Snap.load(getS, onSVGLoaded ) ;
	
	function onSVGLoaded( data ){ 
		s.prepend( data );
	}



	var navOpen = false;
	var mainMenu = $(".mainMenu");
	mainMenu.attr('initH', mainMenu.height());
	TweenMax.set(mainMenu, {height: 0});

	//Menu toggle
	$('.menuBtn').click(function() {
	   
		var menuBtn = $(this),
			body = $("body"),
			nav = $("nav"),
			logo = $(".logo"),
			devlogo = $(".deveoper-logo"),
			a = Expo.easeOut;
	   
	   if ( navOpen == false ){
		   TweenMax.to(nav, 1, {autoAlpha: 1, ease: a});
		   TweenMax.to(mainMenu, 1.2, {height: mainMenu.attr('initH'), ease: a});
		   menuBtn.addClass("active");
		   body.addClass("overflowHide");
		   logo.addClass("active");
		//    devlogo.addClass("active");
		   $window.off("mousewheel.smoothEvent DOMMouseScroll.smoothEvent");
		   navOpen = true;
		   //console.log("open");
	   }else {
		   TweenMax.to(nav, 1, {autoAlpha: 0, ease: a});
		   TweenMax.to(mainMenu, 1.2, {height: "0%", ease: a});
		   menuBtn.removeClass("active");
		   body.removeClass("overflowHide");
		   logo.removeClass("active");
		//    devlogo.removeClass("active");
		//    $window.on("mousewheel.smoothEvent DOMMouseScroll.smoothEvent", smoothScroll);
		   navOpen = false;
		   //console.log("close");
	   };

		//Menu button navigate to section
		$('.mainMenu a').click(function() {
			var sectionID = $(this).attr("href");
			TweenMax.to($window, 0.5, {scrollTo:{y:$(sectionID).offset().top}, delay: 0.5});

			TweenMax.to(nav, 1, {autoAlpha: 0, ease: a});
			TweenMax.to(mainMenu, 1.2, {height: "0%", ease: a});
			menuBtn.removeClass("active");
			body.removeClass("overflowHide");
			logo.removeClass("active");
			devlogo.removeClass("active");
			// $window.on("mousewheel.smoothEvent DOMMouseScroll.smoothEvent", smoothScroll);
			navOpen = false;
			return false;
		});
	   
	   return false;
	});




	//Select Section Index
	var $this = $(this),
		$coffeeSlider = $(".coffee-slider ul"),
		$sections = $('.section-pr').each(function(index){
			$(this).data('sectionIndex', index); //get each Index
			$coffeeSlider.append( "<li></li>" ); //Append each li to slider
			// console.log($(this).data('sectionIndex', index))
		});

		
	//Debounce
	debounce = function(func, wait) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				func.apply(context, args);
			};
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		}
	};

	//Coffee Slider
	var documentHeight = $(document).outerHeight();

	var	scrollTop = $(window).scrollTop(),
		sectionHeight = $sections.first().outerHeight(),
		sectionIndex = Math.round(scrollTop/sectionHeight);
		activeSection = $sections.eq(sectionIndex);
		// console.log(scrollTop);
		// console.log(sectionHeight);
		// console.log(sectionIndex);
		// console.log(activeSection);

	//Debounce Event
	debounceEvent = debounce(function(){
		//Animate to activeSection
		// $('html,body').animate({
		// 	scrollTop: activeSection.offset().top
		// }, 600);
	},800);

	//On Scroll update activeSection & event
	var $window = $(window);		//Window object
	var scrollTime = 1.2;			//Scroll time
	var scrollDistance = 170;		//Distance. Use smaller value for shorter scroll and greater value for longer scroll
	var speed = 100; /*40*/
    var lastScrollTop = 0;
	
    // higher variation = faster acceleration
    function positon(variation) {
        newPosition = Math.round(scrollPercent * (speed * variation)) - (speed * variation);
        // return newPosition + "%";
        return newPosition;
    }
    function invertPositon(variation) {
        newPosition = Math.round(scrollPercent * (speed * variation));
        // return newPosition + "%";
        return newPosition;
    }
	
	$window.on('scroll', function(){
		nowScrollTop = ($this).scrollTop();
		sectionIndex = Math.round(nowScrollTop/sectionHeight);
		sectionIndexNoRound = nowScrollTop/sectionHeight;
		activeSection = $sections.eq(sectionIndex);
		scrollNum = $(window).scrollTop();
		scrollPercent = ($this).scrollTop() / ($(document).height() - sectionHeight);
		
		//If not last section - debounce
		if ( sectionIndex !== $sections.length-1 && !isMobile.any() ){
			debounceEvent();
		}
		bgParallax(scrollPercent);
		switchBtnColor();

	});


	//Bg Parallax
	function bgParallax(scrollPercent){
        if (scrollNum > lastScrollTop) {
            // downscroll
			TweenMax.to($(".noninvert .parallax-bg"), 0.2, {x: positon(2)});
			TweenMax.to($(".invert .parallax-bg"), 0.2, {x: invertPositon(-2)});
        } else {
            //upscroll
			TweenMax.to($(".noninvert .parallax-bg"), 0.2, {x: positon(2)});
			TweenMax.to($(".invert .parallax-bg"), 0.2, {x: invertPositon(-2)});
        }
        lastScrollTop = scrollNum;
	};

	//Switch Button color
	function switchBtnColor(){
		$sectionHome = $("#section-home");
		$sectionComponents = $("#section-components");
		$sectionGallery = $("#section-gallery");
		$sectionInterior = $("#section-interior");
		$sectionVideo = $("#section-video");
		$sectionFindus = $("#section-findus");
		$sectionFacilities = $("#section-facilities");
		$sectionContact = $("#section-contact");
		$sectionRegistration = $("#section-register");

		$sectionHomeOffset = $sectionHome.offset().top - 30;
		$sectionComponentsOffset = $sectionComponents.offset().top - 30;
		// $sectionGalleryOffset = $sectionGallery.offset().top - 30;
		// $sectionInteriorOffset = $sectionInterior.offset().top - 30;
		$sectionVideoOffset = $sectionVideo.offset().top - 30;
		$sectionFindusOffset = $sectionFindus.offset().top - 30;
		$sectionFacilitiesOffset = $sectionFacilities.offset().top - 30;
		$sectionContactOffset = $sectionContact.offset().top - 30;
		$sectionRegistrationOffset = $sectionRegistration.offset().top - 30;

		switch(true){
			case nowScrollTop > $sectionRegistrationOffset:
				$(".menuBtn").addClass("invert");
				break;
			case nowScrollTop > $sectionContactOffset:
				$(".menuBtn").removeClass("invert");
				break;
			case nowScrollTop > $sectionFacilitiesOffset:
				$(".menuBtn").addClass("invert");
				break;
			case nowScrollTop > $sectionFindusOffset:
				$(".menuBtn").removeClass("invert");
				break;
			case nowScrollTop > $sectionVideoOffset:
				$(".menuBtn").addClass("invert");
				break;
			case nowScrollTop > $sectionComponentsOffset:
				$(".menuBtn").addClass("invert");
				break;
				
			default: 
				$(".menuBtn").removeClass("invert");
		}
	};

	
	//Smoothwheel
	$window.on('mousewheel DOMMouseScroll', function(event){
		// $('html,body').stop(true, true);
		// event.preventDefault();	
	});
	$window.on('mousewheel.smoothEvent DOMMouseScroll.smoothEvent', function(event){
		// smoothScroll(event);
	});
	
	function smoothScroll(event){
		var delta = event.originalEvent.wheelDelta/120 || -event.originalEvent.detail/3;
		var scrollTop = $window.scrollTop();
		var finalScroll = scrollTop - parseInt(delta*scrollDistance);
		
		TweenMax.to($window, scrollTime, {
			scrollTo : { y: finalScroll, autoKill:true },
				ease: Power1.easeOut,	//For more easing functions see https://api.greensock.com/js/com/greensock/easing/package-detail.html
				autoKill: true,
				overwrite: 5							
			});
	};


	//Auto Reveal
	window.sr = ScrollReveal();

	sr.reveal('.reveal', {
		reset: true,
		viewFactor: 0.3
	});

	sr.reveal('.reveal-left', {
		reset: true,
		origin: 'left',
		distance: '30px',
		scale: 1,
		viewOffset: { top: 100 }
	});

	sr.reveal('.reveal-right', {
		reset: true,
		origin: 'right',
		distance: '30px',
		scale: 1,
		viewFactor: 0.1,
		viewOffset: { top: 100 }

	});

	//NerveSlider
	if ( isMobile.any() ){
		$(".homeSlider").nerveSlider({
			/*sliderWidth: "100%",
			sliderHeight: "100%",*/
			sliderFullscreen: true,
			sliderAutoPlay: true,
			showTimer: true,
			showPause: false,
			slidesDraggable: false,
			// showDots: false,
			// showArrows: false,
			sliderTheme: "dark",
			slideTransitionSpeed: 1000,
			slideTransitionEasing: "easeOutExpo"
		});
	} else {
		$(".homeSlider").nerveSlider({
			/*sliderWidth: "100%",
			sliderHeight: "100%",*/
			sliderFullscreen: true,
			sliderAutoPlay: false,
			showTimer: false,
			showPause: false,
			// showDots: false,
			// showArrows: false,
			sliderTheme: "dark",
			slideTransitionSpeed: 1000,
			slideTransitionEasing: "easeOutExpo"
		});
	}
	


	//Fullpage
	function initFullpage(){
        $('#fullpage-container').fullpage({
            // anchors: ['firstPage', 'secondPage', '3rdPage'],
            navigation: true,
            navigationPosition: 'right',
            navigationTooltips: ['First page', 'Second page', 'Third and last page'],
            responsive: 900,
            css3: true
        });
    };

    function destroyFullpage(){
        $.fn.fullpage.destroy('all');
    };

    function rebuildFullpage(){
        destroyFullpage();
        initFullpage(); 
    }

    initFullpage();
    destroyFullpage();
    

    $('.btn-explore').click(function(e){
        e.preventDefault();
        $("body").addClass("fullpage-open");
        $(".menuBtn").addClass("menuHide");
        if ( navOpen == true ) {
            $('.menuBtn').trigger("click");
        };
        TweenMax.to($('.crossBtn'), 0.5, {autoAlpha: 1});
        TweenMax.to($('#fullpage'), 0.5, {autoAlpha: 1, y: "0%", onComplete: rebuildFullpage()});
    });

    $('.crossBtn').click(function(e){
        e.preventDefault();
        $("body").removeClass("fullpage-open");
        $(".menuBtn").removeClass("menuHide");
        TweenMax.to($('.crossBtn'), 0.5, {autoAlpha: 0});
        TweenMax.to($('#fullpage'), 0.5, {autoAlpha: 0, y: "-100%", onComplete: destroyFullpage});
    });

    $('.btn-register').click(function(e){
        var sectionID = $(this).attr("href");
        e.preventDefault();
        $("body").removeClass("fullpage-open");
        $(".menuBtn").removeClass("menuHide");
        TweenMax.to($('.crossBtn'), 0.5, {autoAlpha: 0});
        TweenMax.to($('#fullpage'), 0.5, {autoAlpha: 0, y: "-100%", onComplete: destroyFullpage});
        TweenMax.to($(window), 1, {scrollTo:{y:$(sectionID).offset().top}, delay: 0.5});
	});

    $('.btn-components').click(function(e){
		var sectionID = $(this).attr("href");
		TweenMax.set($(".components-block"), {width: "0%"});
		TweenMax.to($(sectionID), 0.5, {width: "100%", className:'+=active'});
		if ( isMobile.any() ){
			TweenMax.to($(window), 0.5, {scrollTo:{y:$(sectionID).offset().top}});
		}
		if ( sectionID == "#section3" ) {
			$(".fullheight").addClass("extend");
		}
		return false;
    });

    $('.btn-back').click(function(e){
		$(".fullheight").removeClass("extend");
		TweenMax.to($(".components-block"), 0.5, {width: "0%", className:'-=active'});
		if ( isMobile.any() ){
			TweenMax.to($(window), 0.5, {scrollTo:{y:$(".components").offset().top}});
		}
		return false;
    });
	
	//Location Map
	$('.location-big').lightGallery({
		selector: 'this',
		download: false,
		counter: false,
		share: false
	});	
	
	//Gallery
	$('.gallery-component').lightGallery({
		selector: 'a',
		getCaptionFromTitleOrAlt: false,
		download: false,
		share: false
	});
	$(".gallery-nav a").click(function(){
		var galleryID = $(this).attr("href");
		$(this).parents(".section-pr").children(".gallery-component").removeClass("active");
		$(galleryID).addClass("active");
		TweenMax.to($window, 0.3, {scrollTo:{y:($(galleryID).offset().top - 60)}});
		return false;
	});


});


// $(window).load(function() {
// 	if ( !$("body").hasClass("loaded") ){
// 		$( "body" ).addClass("loaded");
// 	}
// });
